var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _vm.programList.length > 0
        ? _c(
            "div",
            [
              _c(
                "el-button",
                {
                  staticClass: "ruge-singleBtn-s",
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.fullChoice()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("commons.fullChoice")) + "\n    "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  staticClass: "ruge-singleBtn-s",
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.unFullChoice()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("commons.unFullChoice")) +
                      "\n    "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  staticClass: "ruge-singleBtn-s",
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.grantProgram()
                    },
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("commons.save")) + "\n    ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-row",
        [
          _vm.programList.length > 0
            ? _vm._l(_vm.programList, function (program, index) {
                return _c(
                  "el-col",
                  {
                    key: program.programId,
                    staticClass: "ruge-cards",
                    attrs: { span: 5, offset: index % 4 == 0 ? 0 : 1 },
                  },
                  [
                    _c(
                      "el-card",
                      {
                        class: program.choice,
                        nativeOn: {
                          click: function ($event) {
                            return _vm.choiceCard(program)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "ruge-card-textBox",
                            style: _vm.elCardIcon,
                          },
                          [
                            _c("span", { staticClass: "ruge-card-text" }, [
                              _vm._v(_vm._s(program.programName)),
                            ]),
                          ]
                        ),
                        _c("img", {
                          staticClass: "elCardActive",
                          attrs: { src: _vm.elCardActiveSrc },
                        }),
                      ]
                    ),
                  ],
                  1
                )
              })
            : [
                _c("div", { staticClass: "no-data" }, [
                  _vm._v(_vm._s(_vm.$t("commons.noData"))),
                ]),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }